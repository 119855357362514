import './index.css';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';
import moment from 'moment';
import mailSend from '../../emailkey';
import InputMask from "react-input-mask";

const buildNextValidDates = (count = 3, dayIncrement = 1, dateFormat = 'DD-MM-YYYY') => {
  const list = []
  let innerDayIncrement = dayIncrement
  for (let i = 0; i < count; i++) {
    let returnDate = moment().add(innerDayIncrement, 'days')

    if (returnDate.day() === 0) {
      innerDayIncrement = innerDayIncrement + 1;
    }

    if (returnDate.day() === 6) {
      innerDayIncrement = innerDayIncrement + 2;
    }

    if (list.includes(moment().add(innerDayIncrement, 'd').format(dateFormat))) {
      innerDayIncrement = innerDayIncrement + 1;
    }

    list.push(moment().add(innerDayIncrement, 'd').format(dateFormat))
    innerDayIncrement = innerDayIncrement + 1;
  }
  return { list, dayIncrement: innerDayIncrement };
}

const CustomCalendar = () => {
  const [zip, setZip] = useState('');
  const [tel, setTel] = useState('');
  const [name, setName] = useState('');
  const [changeDate, setChangeDate] = useState(undefined)
  const [colorMail, setColorMail] = useState(true)
  const [colorTel, setColorTel] = useState(true)
  const [colorName, setColorName] = useState(true)
  const [reqest, isOpen] = useState(true);
  const [error, setError] = useState(false)

  const list = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const { list: greenDays, dayIncrement } = buildNextValidDates(3)
  const { list: yellowDays } = buildNextValidDates(3, dayIncrement)

  const handlerClick = () => {
    setError(false)
    if ((tel.indexOf('_') !== -1) | (tel.length === 0)) {
      setColorTel(false);
    } else {
      setColorTel(true);
    }

    if ((zip.indexOf('_') !== -1) | (zip.length === 0)) {
      setColorMail(false);
    } else {
      setColorMail(true);
    }

    if (name.length === 0) {
      setColorName(false)
    } else {
      setColorName(true);
    }

    if (!(zip.indexOf('_') !== -1) && !(tel.indexOf('_') !== -1) && ((name.length && zip.length && tel.length) !== 0)) {
      mailSend(zip, tel, name, changeDate)
        .then(response => {
          if (response.message === "Ops, this form is broken, just call us on the mainline (408) 484-4353") {
            setError(true)
            return
          } else {
            setZip('');
            setTel('');
            setName('');
            setChangeDate(undefined);

            const mainPage = document.getElementById("main-page-all");
            mainPage.classList.remove("show");
            mainPage.classList.add("hidden");

            const requestPage = document.getElementById("request-page");
            requestPage.classList.remove("hidden");
            requestPage.classList.add("show");
          }
        })
        .catch(error => {
          setError(true)
        })
    }
  };

  return (
    <>
      {reqest && <div className="calendar-block">
        <div className="calendar-date">Available service dates</div>
        <div className="calendar-reqest">
          <div className="request-button" onClick={() => isOpen(false)}>Request service</div>
          <div className="request-info">
            You can check with the dispatcher for up-to-date information on free time and,
            if possible, repairs on a ‘red day’.
          </div>
          <div className="calendar-info-logo" />
        </div>
        <div className="custom-calendar">
          <Calendar
            formatShortWeekday={(locale, date) => list[date.getDay()]}
            locale="en"
            prevLabel={<div className="arrow-last"></div>}
            nextLabel={<div className="arrow-next"></div>}
            next2Label={null}
            prev2Label={null}
            minDate={new Date()}
            onChange={setChangeDate}
            tileClassName={({ date }) => {
              const currentDate = moment(date);
              if (moment(date) < new Date()) {
                return 'hide-day'
              }
              if (currentDate.day() === 0 || currentDate.day() === 6) {
                return
              }
              if (greenDays.includes(moment(date).format("DD-MM-YYYY"))) {
                return 'first-3-day'
              }
              if (yellowDays.includes(moment(date).format("DD-MM-YYYY"))) {
                return 'second-3-day'
              }

            }}
          />
        </div>
      </div>}

      {!reqest && <div>
        <div className="calendar-order-form">
          <div>
            <div className="calendar-order-form-title">Leave your application right now</div>
            {error && <div className="calendar-order-form-text">
              Ops, this form is broken, <br /> just call us on the mainline  <br />(408) 484-4353
            </div>}
            {!error && colorMail && colorTel && colorName && <div className="calendar-order-form-text">
              Complete the form, and we'll <br /> call you back soon
            </div>}
            {!error && !(colorMail && colorTel && colorName) && <div className="calendar-order-form-text">
              One or more fields have an error. <br /> Please check and try again.
            </div>}
          </div>
          <div className="calendar-order-inputs">
            <input
              className={colorName ? "calendar-order-input" : "calendar-order-input-red border-red"}
              placeholder="Name"
              type="text"
              value={name}
              onChange={event => setName(event.target.value)}
            ></input>
            <InputMask mask="(999) 999-9999" value={tel} onChange={e => setTel(e.target.value)}>
              {(inputProps) => (
                <input
                  {...inputProps}
                  type="tel"
                  className={colorTel ? "calendar-order-input" : "calendar-order-input-red border-red"}
                  disableUnderline
                  placeholder="Phone number"
                />
              )}
            </InputMask>
            <InputMask mask="99999" value={zip} onChange={e => setZip(e.target.value)}>
              {(inputProps) => (
                <input
                  {...inputProps}
                  type="tel"
                  className={colorMail ? "calendar-order-input" : "calendar-order-input-red border-red"}
                  disableUnderline
                  placeholder="Zip"
                />
              )}
            </InputMask>
            <button
              className="calendar-order-button"
              onClick={handlerClick}
            >send request</button>
          </div>
        </div>
      </div>}
    </>
  )
}

export default CustomCalendar;

