import './index.css';

const HeaderBar = ({isOpenMenu}) => {
  return (
    <div className="bar-form" >
      <div className="bar-logo-block">
        <div className="bar-logo"></div>
        <div className="bar-close" onClick={() => isOpenMenu(false)}></div>
      </div>
      <div className="bar-title">NAVIGATION</div>
      <a href="#brand" className="bar-text" onClick={() => isOpenMenu(false)}>About brand</a>
      <a href="#appliance" className="bar-text" onClick={() => isOpenMenu(false)}>Brand appliance</a>
      <a href="#team" className="bar-text" onClick={() => isOpenMenu(false)}>Our team</a>
      <a href="#repair" className="bar-text" onClick={() => isOpenMenu(false)}>Repair appliance</a>
      <div className="bar-title">SERVICES</div>
      <a href="#calendar" className="bar-text" onClick={() => isOpenMenu(false)}>Repair calendar <div className="bar-dot"></div></a>
      <a href="#request" className="bar-text" onClick={() => isOpenMenu(false)}>Request service </a>
      <div className="bar-title">CONTACTS</div>
      <a href="tel:+1 (408)637-4884" className="bar-phone">(408) 637-4884</a>
      <a href="mailto:service@master-appliance.com" className="bar-mail">service@master-appliance.com</a>
    </div>
  )
}

export default HeaderBar;
