import './index.css';
import { useState, useEffect  } from 'react';
import HeaderBar from '../HeaderBar';

const MainMenu = () => {
  const [barMenu, isOpenMenu] = useState(false);

  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const currentScroll = () => {
        if ((window.innerWidth > 1399) && (window.scrollY > 700 )) {
          return true
        } else if ((window.innerWidth > 740) && (window.scrollY > 850 )) {
          return true
        } else if ((window.innerWidth < 740) && (window.scrollY > 1100 )){
          return true
        } else {
          return false
        }
      }
      setScroll(currentScroll)
    })
  })

  return (
    <div className="all-main-menu">
      {scroll && <div className="mainMenuColor">
        <div className="main-menu">
            {barMenu && <div className="header-bar-shadow">
              <div className="header-bar-component"> <HeaderBar isOpenMenu={isOpenMenu} /> </div>
            </div>}
            <div className="main-menu-icon"></div>
            <div className="main-menu-items">
              <a href="#brand" className="main-menu-item">About</a>
              <a href="#appliance" className="main-menu-item">Brand appliance</a>
              <a href="#team" className="main-menu-item">Our team</a>
              <a href="#repair" className="main-menu-item">repair appliance</a>
              <a href="#calendar" className="main-menu-item">Repair calendar <div className="main-menu-dot"></div></a>
            </div>
            <a href="tel:+1 (408)637-4884" className="main-menu-phone">(408) 637-4884</a>
            <div className="main-menu-button" onClick={() => isOpenMenu(true)} ></div>
        </div>
      </div>}
      {!scroll && <div className="main-menu-top">
        {barMenu && <div className="header-bar-shadow">
          <div className="header-bar-component"> <HeaderBar isOpenMenu={isOpenMenu} /> </div>
        </div>}
        <div className="main-menu-icon-top"></div>
        <div className="main-menu-items">
          <a href="#brand" className="main-menu-item-top">About</a>
          <a href="#appliance" className="main-menu-item-top">Brand appliance</a>
          <a href="#team" className="main-menu-item-top">Our team</a>
          <a href="#repair" className="main-menu-item-top">repair appliance</a>
          <a href="#calendar" className="main-menu-item-top">Repair calendar <div className="main-menu-dot"></div></a>
        </div>
        <a href="tel:+1 (408)637-4884" className="main-menu-phone-top">(408) 637-4884</a>
        <div className="main-menu-button-top" onClick={() => isOpenMenu(true)} ></div>
      </div>}
    </div>
  )
}

export default MainMenu;
